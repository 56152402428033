<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left" v-if="permissionEnabled('links', 'edit') || permissionEnabled('links', 'delete')">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :id="`action-${l.type}`" :class="l.color ? l.color : 'has-text-grey-light'" v-show="showButtons(l.type)" @click="handleClick(l, id)">
			<svg-icon :icon="l.icon"></svg-icon>
			<span>{{ $t(l.name) }}</span>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'

export default {
	name: 'Trigger',
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: Number,
			required: true
		},
		items: {
			type: Array,
			required: false,
			default: () => {
				return [
					{
						id: 1,
						name: 'labels.edit',
						icon: 'edit',
						type: 'edit'
					},
					{
						id: 2,
						name: 'labels.delete' ,
						icon: 'trash',
						color: 'has-text-danger',
						type: 'delete'
					}
				]
			}
		}
	},
	data() {
		return {
			btn: true
		}
	},
	methods: {
		handleClick(el, id) {
			const name = el.id
			if (name === 1) {
				eventHub.$emit('edit-modal-link', { id })
			} else {
				eventHub.$emit('delete-link', { id })
			}
		},
		showButtons(type) {
			let show = false

			if (this.permissionEnabled('links', type)) {
				show = true
			}

			return show
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
