<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveLink)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.links') }}</strong></span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
                    <InputWithValidation rules="required" class="mb-3" :label="$tc('fields.name')" v-model="form.name" />
					<InputWithValidation rules="required" class="mb-3" :label="$tc('fields.link')" v-model="form.url" :disabled="name == 'Edit'" />
					<InputWithValidation class="mb-3" :label="$tc('fields.url_custom')" v-model="form.hash" :disabled="name == 'Edit'" />

					<small class="modal-updated" v-if="form.updated_at">{{ $t('labels.last_change') }} {{ format(form.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			permission: [],
			visible: false,
			form: {
				name: '',
				url: '',
				hash: ''
			}
		}
	},
	methods: {
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`links/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response
						this.form = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`links/update/${this.id}`, this.form)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/links')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.links')]))
					eventHub.$emit('reload-links')
				}
			} catch (e) {
				const { status } = e
				if (status === 400) {
					errorToast(this.$t('alerts.update.error', [this.$tc('menu.links')]))
				}
			} finally {
				this.loading = false
			}
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('links/store', this.form)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/links')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.links')]))
					eventHub.$emit('reload-links')
				}
			} catch (e) {
				const { status } = e
				if (status === 400) {
					const { message } = e.data
					errorToast(this.$t('alerts.create.error', [this.$tc('menu.links')]) + '<small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}
		},
		async saveLink() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
